import React, { useEffect, useRef, useContext, useState } from "react";
import "./TelephoneSearch.scss";
import { GetClaimByPhoneNumber } from "../../../helpers/Api";
// import { TextInput } from "ebs-component-library";
import { InputText } from 'primereact/inputtext';
import { AppContext } from "../../../context/AppContext";

const TelephoneSearch = () => {
  const [claimsByPhoneNumber, getClaimsByPhoneNumber] = GetClaimByPhoneNumber();
  const url = new URLSearchParams(window.location.search);
  const phoneNumber = url.get("phoneNumber");
  const [phoneNumberInput, setphoneNumberInput] = useState(phoneNumber || "");
  const { phoneSearchDispatch, translations } = useContext(AppContext);
  const searchButtonRef = useRef(null);

  useEffect(() => {
    const routeTo = claimsByPhoneNumber.data
      ? claimsByPhoneNumber.data.length > 1
        ? "/"
        : claimsByPhoneNumber.data.length === 1
        ? "/" + claimsByPhoneNumber.data[0].id
        : "/"
      : "";

    phoneSearchDispatch({
      type: "update",
      value: claimsByPhoneNumber,
      routeTo: routeTo
    });
  }, [claimsByPhoneNumber, phoneSearchDispatch]);

  const enterKeyHandler = e => {
    if (e.keyCode === 13) {
      if (searchButtonRef.current) {
        searchButtonRef.current.click();
      }
    }
  };

  const handleSearchClick = e => {
    getClaimsByPhoneNumber(phoneNumberInput);
  };

  useEffect(() => {
    if (phoneNumberInput) {
      getClaimsByPhoneNumber(phoneNumberInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", enterKeyHandler);

    return () => {
      window.removeEventListener("keydown", enterKeyHandler);
    };
  }, []);

  return (
    <div className="telephone-search-container">
      <InputText
        onChange={(e) => setphoneNumberInput(e.target.value)}
        // onChange={text => setphoneNumberInput(text)}
        placeholder={translations.phoneSearchBar.hint}
        value={phoneNumberInput}
        className="telephone-input"
      />
      <button
        ref={searchButtonRef}
        onClick={handleSearchClick}
        className="search-button"
        data-testid="search-button"
        type="button"
      >
        <span className="magnifying-glass" />
      </button>
    </div>
  );
};

export default TelephoneSearch;
