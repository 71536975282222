import React, {useContext} from "react";
import "./ClaimOverview.scss";
// import {Card, DataTable} from "ebs-component-library";
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {Link as RouterLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import moment from "moment-timezone";

const ClaimOverview = () => {
  const { phoneSearchResultState, translations } = useContext(AppContext);

  let headers = [];
  if(translations) {
     headers = translations.claimDetailsHeadings.split(",");
  }

  const setClassName = status => {
    return status === true ? "sent" : "icon-x";
  };

  const rows = () => {
    const showClaims = phoneSearchResultState.data
      ? phoneSearchResultState.data
      : [];

    Object.keys(showClaims).map(id => {
      const claim = showClaims[id];
      var m = moment.utc(claim.lastUpdate, "DD-MM-YYYY h:mm:ss A");
      var lastUpdateWithTZ = m.clone().tz("Asia/Tokyo").format("YY/MM/DD (HH:mm)");
      claim.lastUpdate = lastUpdateWithTZ ? lastUpdateWithTZ: "N/A";
     })

    return showClaims;
    // return Object.keys(showClaims).map(id => {
    //   const claim = showClaims[id];
    //   const lastUpdateWithTZ =
    //     moment.tz(claim.lastUpdate, "UTC").tz("Asia/Tokyo").format("YY/MM/DD (HH:mm)");

      
    //   return [
    //     <RouterLink className="claim-id-router-link" to={`/${claim.id}`}>
    //       {claim.claimId !== "null" ? claim.claimId : "N/A"}
    //     </RouterLink>,

    //     <div>{claim.lastUpdate ? lastUpdateWithTZ : "N/A"}</div>,
    //     <div className={setClassName(claim.sent)} />
    //   ];
    // });
  };

  const guidTemplate = (claim) => {
    const claimId = claim['claimId'];
    const guid = claim['id'];
    return <Link to={"/" + guid}>{claimId}</Link>
 }

  const renderEmptyState = () => {
    if(translations.noFNOLMessage != "null") {
      return (
        <div className="overview-empty-state">{translations.noFNOLMessage}</div>
      );
    } else {
      return '';
    }

  };

  return (
    <div className="search-results-container">
        {phoneSearchResultState.data &&
          phoneSearchResultState.data.length > 0 ? (
            <DataTable value={rows()}  >
              <Column field="id" header="Id" hidden></Column>
              <Column field="claimId" header={headers[0]} body={guidTemplate}></Column>
              <Column field="lastUpdate" header={headers[1]}></Column>
              <Column field="sent" header={headers[2]}></Column>
            </DataTable>
          ) : (
            renderEmptyState()
          )}
    </div>
  );
};

export default ClaimOverview;
